import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from "react-router-dom";
import { Table, Pagination, Spin, Icon, Col, Row } from 'antd-min';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { PaginationProps } from 'antd/lib/pagination';
import { withRouter, GLUtil, GLGlobal, GLLocale, NotificationType, MessageHelper, unmaskThrottle, maskThrottle } from 'gl-commonui';
import { SchoolPathConfig } from '@app/config/pathconfig';
import { SchoolLocale } from "@app/locales/localeid";
import { MainTitle } from '@app/components';
import { ClassesModel, ClassesPropsModel } from '@app/service/class/model'
import { StateType } from '@app/states';
import { getClasses, getClassListExcel } from '@app/states/school/classes';
import './classes.less';
import { SchoolClassDayName, BlobHelper } from '@app/util';

interface ClassesProps extends RouteComponentProps<any> {
    loading?: boolean
    classes?: ClassesModel[]
    dataSource?: ClassesModel[]
    total?: number
    getClasses: (d) => void
    getClassListExcel: (d) => void
}

class ClassesTable extends Table<any> { }

@withRouter
@connect(({ classes: { loading, list, total, dataSource } }: StateType) => ({ loading, classes: list, total, dataSource }), { getClasses, getClassListExcel })
export class ClassesPage extends Component<ClassesProps, any> {
    tableProps: TableProps<ClassesModel> = {
        rowKey: 'id',
        pagination: false
    };
    pagingProps: PaginationProps = {
        pageSize: 30,
        current: 1,
        className: 'ant-table-pagination',
        showTotal: (total, range) => GLGlobal.intl.formatMessage({ id: GLLocale.Pagination }, { from: range[0], to: range[1], total })
    };
    getColumns(): ColumnProps<ClassesModel>[] {
        const fmtMsg = GLGlobal.intl.formatMessage;
        let columns: ColumnProps<ClassesModel>[] = [
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnSchool }),
                dataIndex: ClassesPropsModel.schoolName,
                width: "12%",
                className: "school-col",
                render: (text, record, index) => {
                    const obj = {
                        children: this.getSchoolColumnContent(text, record, index),
                        props: { rowSpan: record.rowSpan },
                    };
                    return obj;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnClass }),
                dataIndex: ClassesPropsModel.schoolClassName,
                width: "12%",
                className: "class-col",
                render: (text, record, index) => {
                    const obj = {
                        children: this.getClassColumnContent(text, record, index),
                        props: { rowSpan: record.rowSpan },
                    };
                    return obj;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnTeacher }),
                dataIndex: ClassesPropsModel.teacherName,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnAgeGrade }),
                dataIndex: ClassesPropsModel.age,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnStudentCount }),
                dataIndex: ClassesPropsModel.studentCount,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnUnit }),
                dataIndex: ClassesPropsModel.unit,
                width: "8%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnTSIREP }),
                dataIndex: ClassesPropsModel.tsi_rep,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index, 1);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnDuration }),
                dataIndex: ClassesPropsModel.duration,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index, 1);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnCount }),
                dataIndex: ClassesPropsModel.count,
                width: "10%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text, record, index, 1);
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassesColumnDays }),
                dataIndex: ClassesPropsModel.days,
                width: "8%",
                render: (text, record, index) => {
                    return this.getCommonColumnContent(text ? this.getClassDays(text) : null, record, index, 1);
                }
            }
        ];
        return columns;
    }
    getClassDays(days: string) {
        return days.split('/').map(day => GLGlobal.intl.formatMessage({ id: SchoolClassDayName[day] })).join('/');
    }
    viewList({ dataSource }: { dataSource: ClassesModel[] }) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <>
                <div className='table-list'>
                    {dataSource && dataSource.map((data, index) => {
                        return (
                            <div className='item table-list-item' key={data.id}>
                                <div className='title'>{this.getClassColumnContent(data.schoolClassName, data, index)}</div>
                                <div className='label'><span>{fmtMsg({ id: SchoolLocale.ClassesColumnSchool })}:</span>{this.getSchoolColumnContent(data.schoolName, data, index)}</div>
                                <div className='label'><span>{fmtMsg({ id: SchoolLocale.ClassesColumnTeacher })}:</span>{data.teacherName}</div>
                                <div className='label'><span>{fmtMsg({ id: SchoolLocale.ClassesColumnAgeGrade })}:</span>{data.age}</div>
                                <div className='students'><span><span>{fmtMsg({ id: SchoolLocale.ClassesColumnStudentCount })}:</span>{data.studentCount}</span><span><span>{fmtMsg({ id: SchoolLocale.ClassesColumnUnit })}:</span>{data.unit}</span></div>
                                {(data.tsi || data.tsiTimePerWeek) && <div className='tsirep'><span>{this.formatTsiRep(<span>TSI:</span>, data.tsi, data.tsiTimePerWeek)}</span></div>}
                            </div>
                        )
                    })}
                </div>
                {/* <div className='table-list-page'><Pagination {...pagination} /></div> */}
            </>
        )
    }
    formatTsiRep(prefix, data, duration) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const text = []
        if (!data && !duration) {
            return ''
        }
        if (!data && duration) {
            return <>{prefix}{fmtMsg({ id: SchoolLocale.ClassesListDuration }, { duration: duration })}</>
        }
        if (data.days) {
            text.push(this.getClassDays(data.days))
        }
        if (data.count) {
            text.push(fmtMsg({ id: SchoolLocale.ClassesListCount }, { count: data.count }))
        }
        if (data.duration) {
            text.push(fmtMsg({ id: SchoolLocale.ClassesListDuration }, { duration: duration || data.duration }))
        }
        return <>{prefix}{text.join(', ')}</>
    }
    getSchoolColumnContent(text: string, record: ClassesModel, index: number) {
        return (
            <Link to={{
                pathname: GLUtil.pathStringify(SchoolPathConfig.Schools, { schoolId: record.schoolId }),
                state: { fromClasses: true }
            }}>
                {text}
            </Link>
        )
    }
    getClassColumnContent(text: string, record: ClassesModel, index: number) {
        return (
            <Link to={{
                pathname: GLUtil.pathStringify(SchoolPathConfig.Students, { schoolId: record.schoolId, campusId: record.campusId, classId: record.schoolClassId }),
                state: { fromClasses: true }
            }}>
                {text}
            </Link>
        )
    }
    getCommonColumnContent(text: string, record: ClassesModel, index: number, rowSpan?: number) {
        const obj = {
            children: text,
            props: { rowSpan: rowSpan ? rowSpan : record.rowSpan },
        };
        return obj;
    }
    componentDidMount() {
        this.props.getClasses({ query: this.getPagingQuery(1) });  ////getQuery('classes').current;
    }
    // onPagingChange(pagination) {
    //     this.props.getClasses({ query: this.getPagingQuery(pagination.current ? pagination.current : pagination) });
    // }
    onPagingChange(page) {
        this.pagingProps.current = page;
        this.props.getClasses({ query: this.getPagingQuery(page) });
    }
    // getPagingQuery(current) {
    //     const page = this.tableProps.pagination as PaginationParams;
    //     page.current = current;
    //     return {
    //         offset: (current - 1) * page.pageSize / 2,
    //         limit: page.pageSize / 2
    //     }
    // }
    getPagingQuery(current) {
        return {
            offset: (current - 1) * this.pagingProps.pageSize,
            limit: this.pagingProps.pageSize
        }
    }

    exportclassListingExcel = () => {
        const params = {};
        maskThrottle();
        this.props.getClassListExcel({ query: {} });
    }

    render() {
        const { classes, total, loading, dataSource } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        this.tableProps.columns = this.getColumns();
        this.tableProps.dataSource = classes;
        const exportText = fmtMsg({ id: SchoolLocale.ClassListExport });
        //this.tableProps.loading = loading;
        return (
            <div className='content-layout'>
                <MainTitle title={SchoolLocale.ClassesTitle} />
                <div className="classes-page">
                    <Spin spinning={loading}>
                        <Row>
                            <Col span={22}></Col>
                            <Col span={2} className="export-class-list"><div onClick={() => this.exportclassListingExcel()}><Icon type="download"></Icon><span> {exportText}</span></div></Col>
                        </Row>
                        <ClassesTable className="responsive-table" {...this.tableProps} />
                        {this.viewList({ dataSource })}
                        {classes.length > 0 &&
                            <Pagination
                                {...this.pagingProps}
                                onChange={this.onPagingChange.bind(this)}
                                total={total}
                            />
                        }
                    </Spin>
                </div>
            </div>
        );
    }
}

